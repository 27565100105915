import { NAMESPACES } from 'constants/locales'

import React, { memo, useCallback, useMemo } from 'react'
import Headline from '@typeform/ginger/dist/components/typography/headline/v2'
import { fontSize } from '@typeform/ginger/dist/constants/typography'
import Text, {
  SIZE as TEXT_SIZE,
} from '@typeform/ginger/dist/components/typography/text/next'
import { routes } from 'utils/routes'
import Markdown from 'components/atoms/markdown'
import useTranslation from 'next-translate/useTranslation'
import { trackRedirectToPage } from 'components/tracking'

import SectionContainer from '../common/section-container'
import TrackingLocation, {
  TRACKING_LOCATIONS,
} from '../common/tracking-location'

import {
  ProfileList,
  TemplateList,
  Link,
  CTAContainer,
} from './profiles-section.styles'

const getMarkdownComponentMap = ({ onLinkClick }) => ({
  h2: ({ node, ...props }) => (
    <Headline
      as='h2'
      largeViewportsFontSize={fontSize.xLarge}
      mediumViewportsFontSize={fontSize.large}
      smallViewportsFontSize={fontSize.large}
      {...props}
    />
  ),
  ul: ({ depth, node, ...props }) =>
    depth === 0 ? <ProfileList {...props} /> : <TemplateList {...props} />,
  a: props => <Link {...props} onClick={onLinkClick} />,
})

const ProfilesSection = () => {
  const { t, lang } = useTranslation(NAMESPACES.HOME)

  const transformTemplateSlugToLink = useCallback(
    templateSlug => routes(lang).templates.template(templateSlug),
    [lang]
  )

  const handleBrowseTemplatesClick = useCallback(() => {
    trackRedirectToPage({
      label: t('homepage.templates.cta.label'),
      linkUrl: routes(lang).templates.path,
      location: TRACKING_LOCATIONS.TEMPLATES,
    })
  }, [t, lang])

  const handleTemplateLinkClick = useCallback(event => {
    const linkElement = event.currentTarget
    trackRedirectToPage({
      label: linkElement.textContent,
      linkUrl: linkElement.href,
      location: TRACKING_LOCATIONS.TEMPLATES,
    })
  }, [])

  const markdownComponentMap = useMemo(
    () => getMarkdownComponentMap({ onLinkClick: handleTemplateLinkClick }),
    [handleTemplateLinkClick]
  )

  return (
    <TrackingLocation name={TRACKING_LOCATIONS.TEMPLATES}>
      <SectionContainer data-testid='profiles-section'>
        <Markdown
          components={markdownComponentMap}
          transformLinkUri={transformTemplateSlugToLink}
        >
          {t('homepage.templates.list')}
        </Markdown>
        <CTAContainer>
          <Text
            as='a'
            href={routes(lang).templates.path}
            onClick={handleBrowseTemplatesClick}
            emphasised
            underlined
            extraSmallViewportsSize={TEXT_SIZE.S}
            smallViewportsSize={TEXT_SIZE.M}
            mediumViewportsSize={TEXT_SIZE.M}
            largeViewportsSize={TEXT_SIZE.M}
          >
            {t('homepage.templates.cta.label')}
          </Text>
        </CTAContainer>
      </SectionContainer>
    </TrackingLocation>
  )
}

ProfilesSection.displayName = 'ProfilesSection'

export default memo(ProfilesSection)
