import styled from 'styled-components'
import { BREAKPOINTS } from '@typeform/ginger/dist/constants/design'
import {
  fontSize,
  fontWeight,
  lineHeight,
} from '@typeform/ginger/dist/constants/typography'
import mediaqueries from '@typeform/ginger/dist/util/mediaqueries'
import { spacing } from '@typeform/ginger/dist/util/spacing'
import { NeutralColors } from '@typeform/ginger/dist/constants/colors'

export const ProfileList = styled.ul`
  grid-column: 1 / -1;
  display: grid;
  row-gap: 32px;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(3, 1fr);
  list-style: none;
  margin: 0;
  padding: 0;

  > li {
    padding: 32px;
    background-color: ${NeutralColors.GreyPaper};
    border-radius: 24px;

    h3 {
      font-size: ${fontSize.large};
      font-weight: ${fontWeight.regular};
      line-height: ${lineHeight.xSmall};
    }

    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
      &:nth-of-type(1n + 4) {
        display: none;
      }
    }

    ${mediaqueries.sm`
      &:hover {
        background-color: ${NeutralColors.Ink};
        color: ${NeutralColors.White};
      }
    `}
  }

  ${mediaqueries.sm`
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    column-gap: 24px;
  `}

  ${mediaqueries.md`
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    column-gap: 24px;
    row-gap: 24px;
  `}

  ${mediaqueries.lg`
    column-gap: 32px;
    row-gap: 24px;
  `}
`

export const TemplateList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 16px;

  > li {
    display: flex;
    align-items: flex-start;
    &::before {
      padding-top: 2px;
      content: '→';
      font-size: ${fontSize.medium};
    }
  }
`

export const Link = styled.a`
  width: auto;
  font-weight: ${fontWeight.regular};
  font-size: ${fontSize.small};
  line-height: ${lineHeight.xSmall};
  display: inline-block;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 4px;

  &:hover {
    text-decoration: underline;
  }

  ${mediaqueries.lg`
    font-size: ${fontSize.medium};
  `}
`

export const CTAContainer = styled.div`
  grid-column: 1/-1;
  margin: auto;
  margin-top: ${spacing(4)};

  ${mediaqueries.sm`
    margin-top: ${spacing(8)};
  `}

  ${mediaqueries.md`
    margin-top: ${spacing(10)};
  `}
`
